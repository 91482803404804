import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import imgCart from "../../assets/icon/cart.png";
import imgChart from "../../assets/icon/chart.png";
import imgCoin from "../../assets/icon/coin.png";
import imgGarbageTruck from "../../assets/icon/GarbageTruck.png";
import imgGroup from "../../assets/icon/Group.png";
import imgHouse from "../../assets/icon/house.png";
import imgSetting from "../../assets/icon/Settings.png";
import imgWrite from "../../assets/icon/write.png";
import imgJourney from "../../assets/icon/Journey.svg";
import imgPersonalData from "../../assets/icon/personal-data.png";
import { ReactComponent as ArrowDown } from "../../assets/icon/arrow-down.svg";

import logoNewChok from "../../assets/logo/logo-new-chok.png";

import DropdownMenu from "../dropdown/DroupDownMenu";
import { getStorage } from "../../utils/storage";
import { cx } from "@emotion/css";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [permissions, setPermissions] = useState<any>({});
  const [isSideBarCollapse, setIsSideBarCollapse] = useState<boolean>(false);

  const onClickSidebarCollapse = useCallback(() => {
    setIsSideBarCollapse(!isSideBarCollapse);
  }, [isSideBarCollapse]);

  const isActive = (path: string) => location.pathname === path;

  const checkPermission = (group: string, name: string) => {
    if (permissions[group] === undefined) {
      return false;
    }
    if (name === "") {
      return true;
    }
    if (permissions[group][name] === undefined) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const userInfo = getStorage("userInfo");
    if (userInfo) {
      const user = JSON.parse(userInfo);
      const userPermissions = user["user_permissions"];
      var temp: any = {};
      for (const index in userPermissions) {
        if (userPermissions[index]["is_activated"]) {
          const name = userPermissions[index]["permissions"]["name"];
          const title = userPermissions[index]["permissions"]["title"];
          if (temp[title] === undefined) {
            temp[title] = {};
          }
          temp[title][name] = true;
        }
      }
      setPermissions(temp);
      // console.log(temp);
    } else {
      console.log("No user info found");
    }
  }, []);

  return (
    <div className="relative flex h-full">
      {/* Sidebar */}
      <div
        onClick={onClickSidebarCollapse}
        className="absolute -right-4 top-[48px] z-50 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white shadow-lg shadow-grey-dark"
      >
        <ArrowDown
          className={cx(
            "w-4 transition-all duration-700",
            isSideBarCollapse ? "-rotate-90" : "rotate-90",
          )}
        />
      </div>
      <aside
        className={cx(
          "flex flex-col text-white transition-all duration-500 ease-in-out",
          isSideBarCollapse ? "w-16" : "w-64",
        )}
      >
        {/* Brand Section */}
        <div className="flex items-center space-x-4 p-4">
          <img
            src={logoNewChok}
            alt="Logo"
            className={cx(
              "h-24 w-[224px] transition-opacity",
              isSideBarCollapse
                ? "!opacity-0 !duration-100"
                : "opacity-100 duration-[3s]",
            )}
          />
        </div>
        {/* Navigation Links */}
        <nav>
          <Link
            to="/"
            className={cx(
              `flex h-12 items-center gap-2 px-4 py-2.5 hover:bg-blue-600`,
              isActive("/") ? "bg-white/20" : "",
              checkPermission("dashboard", "") ? "" : "hidden",
            )}
          >
            <img src={imgHouse} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              หน้าหลัก
            </span>
          </Link>
          <DropdownMenu
            title="ข้อมูลลูกค้า"
            icon={
              <img
                src={imgPersonalData}
                alt="Personal Data Icon"
                className="h-6 w-6"
              />
            }
            submenus={[
              {
                name: "ลูกค้าลงทะเบียนใหม่",
                url: "/new-customers",
                isActivate: checkPermission("customer", "customer-new"),
              },
              {
                name: "ลูกค้าทั้งหมด",
                url: "/customers",
                isActivate: checkPermission("customer", "customer-all"),
              },
            ]}
            isCollapse={isSideBarCollapse}
          />
          <Link
            to="/users"
            className={cx(
              "flex h-12 items-center gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("admin", "") ? "" : "hidden",
            )}
          >
            <div className="h-6 w-6">
              <svg
                width="35px"
                height="35px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
              >
                <path
                  d="M6.02958 19.4012C5.97501 19.9508 6.3763 20.4405 6.92589 20.4951C7.47547 20.5497 7.96523 20.1484 8.01979 19.5988L6.02958 19.4012ZM15.9802 19.5988C16.0348 20.1484 16.5245 20.5497 17.0741 20.4951C17.6237 20.4405 18.025 19.9508 17.9704 19.4012L15.9802 19.5988ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM13 10C13 10.5523 12.5523 11 12 11V13C13.6569 13 15 11.6569 15 10H13ZM12 11C11.4477 11 11 10.5523 11 10H9C9 11.6569 10.3431 13 12 13V11ZM11 10C11 9.44772 11.4477 9 12 9V7C10.3431 7 9 8.34315 9 10H11ZM12 9C12.5523 9 13 9.44772 13 10H15C15 8.34315 13.6569 7 12 7V9ZM8.01979 19.5988C8.22038 17.5785 9.92646 16 12 16V14C8.88819 14 6.33072 16.3681 6.02958 19.4012L8.01979 19.5988ZM12 16C14.0735 16 15.7796 17.5785 15.9802 19.5988L17.9704 19.4012C17.6693 16.3681 15.1118 14 12 14V16Z"
                  fill="#fff"
                />
              </svg>
            </div>

            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              ผู้ใช้งานระบบ
            </span>
          </Link>
          <Link
            to="/"
            className={cx(
              "flex gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("sales-document", "") ? "" : "hidden",
            )}
          >
            <img src={imgCoin} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              เอกสารขาย
            </span>
          </Link>
          <Link
            to="/route-way"
            className={`flex gap-2 px-4 py-2.5 hover:bg-blue-600`}
          >
            <img src={imgJourney} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              เส้นทางเดินรถ
            </span>
          </Link>
          <Link
            to="/"
            className={cx(
              "flex gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("contract", "") ? "" : "hidden",
            )}
          >
            <img src={imgWrite} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              สัญญา
            </span>
          </Link>
          <Link
            to="/"
            className={cx(
              "flex gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("garbage", "") ? "" : "hidden",
            )}
          >
            <img src={imgGarbageTruck} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              การจัดการมูลฝอย
            </span>
          </Link>
          <Link
            to="/"
            className={cx(
              "flex gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("purchase-document", "") ? "" : "hidden",
            )}
          >
            <img src={imgCart} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              เอกสารซื้อ
            </span>
          </Link>
          <Link
            to="/"
            className={cx(
              "flex gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("expense", "") ? "" : "hidden",
            )}
          >
            <img src={imgGroup} className="h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              ค่าใช้จ่าย
            </span>
          </Link>
          <Link
            to="/"
            className={cx(
              "flex gap-2 px-4 py-2.5 hover:bg-blue-600",
              checkPermission("report", "") ? "" : "hidden",
            )}
          >
            <img src={imgChart} className="mr-2 h-6 w-6" alt="" />
            <span
              className={cx(
                "whitespace-nowrap opacity-100 transition-all duration-700",
                isSideBarCollapse ? "!opacity-0 duration-100" : "duration",
              )}
            >
              รายงาน
            </span>
          </Link>
          <div className="m-2 mx-4 my-4">
            <div className="border-b-2 border-white"></div>
          </div>

          <DropdownMenu
            title="การตั้งค่า"
            icon={
              <img
                src={imgSetting}
                alt="Personal Data Icon"
                className="mr-2 h-6 w-7"
              />
            }
            submenus={[
              {
                name: "ข้อมูลธุรกิจ",
                url: "/setting/businessInformation",
                isActivate: checkPermission("customer", ""),
              },
              {
                name: "การตั้งค่าเอกสาร",
                url: "/setting/setupdoc",
                isActivate: checkPermission("customer", "customer-new"),
              },
              {
                name: "ตั้งค่าหมวดสินค้า",
                url: "/setting/product-category",
                isActivate: checkPermission(
                  "setting",
                  "setting-product-category",
                ),
              },
              {
                name: "ตั้งค่ารายการสินค้า",
                url: "/setting/product",
                isActivate: checkPermission("setting", "setting-product"),
              },
              {
                name: "ตั้งค่าข้อมูลรถยนต์",
                url: "/setting/truck",
                isActivate: checkPermission("customer", "customer-all"),
              },
              {
                name: "ตั้งค่าเส้นทาง",
                url: "/setting/route",
                isActivate: checkPermission("customer", "customer-all"),
              },
              {
                name: "ตั้งค่าเส้นทางเดินรถ",
                url: "/setting/truck-route",
                isActivate: checkPermission("customer", "customer-all"),
              },
            ]}
            isCollapse={isSideBarCollapse}
          />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
