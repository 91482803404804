import React, { ReactNode, useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";

interface LayoutWebProps {
  children: ReactNode;
}

const LayoutWeb: React.FC<LayoutWebProps> = ({ children }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <>
      <div className="max-w-ful flex min-h-screen flex-row bg-blue-800/95 font-kanit">
        <Sidebar />

        <div className="w-full flex-col overflow-x-hidden rounded-l-[30px] bg-[#fafafa]">
          <Navbar handleSubMenuToggle={handleSubMenuToggle} />
          <div className="flex flex-1">
            <main className="flex-1 overflow-x-auto p-4">{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutWeb;
