import { cx } from "@emotion/css";
import React, { useState, useEffect, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface Submenu {
  name: string;
  url: string;
  isActivate: boolean;
}

interface DropdownMenuProps {
  title: string;
  submenus: Submenu[];
  isCollapse: boolean;
  icon: ReactNode;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  title,
  submenus,
  isCollapse,
  icon,
}) => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let count = 0;
    submenus.forEach((data) => {
      if (data.isActivate) {
        count += 1;
      }
      if (isActive(data.url)) {
        setIsOpen(true);
      }
    });
    if (count === 0) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [submenus]);

  return (
    <>
      {showMenu ? (
        <>
          <div
            className="group flex h-12 w-full cursor-pointer items-center p-2 px-4 py-2.5 text-base text-white transition duration-75 hover:bg-blue-600"
            onClick={toggleDropdown}
          >
            <div className="mr-auto flex gap-2">
              <div className="h-6 w-6">{icon}</div>
              <span
                className={cx(
                  "whitespace-nowrap opacity-100 transition-opacity",
                  isCollapse ? "!opacity-0 duration-200" : "duration-700",
                )}
              >
                {title}
              </span>
            </div>

            <div
              className={cx(
                "h-1.5 w-1.5 rounded-full bg-white opacity-100 transition-opacity",
                isCollapse
                  ? "absolute right-2 opacity-100"
                  : "relative !opacity-0",
              )}
            />
            <svg
              className={cx(
                "h-6 w-6 opacity-100 transition-all",
                isOpen ? "rotate-180" : "",
                isCollapse ? "!opacity-0 duration-200" : "duration-700",
              )}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>

          {/* Dropdown menu with smooth animation */}
          <div
            className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
              isOpen ? "max-h-full" : "max-h-0"
            }`}
          >
            <ul className="space-y-2 py-2">
              {submenus.map((submenu, index) => (
                <li key={index}>
                  {submenu.isActivate === true ? (
                    <Link
                      to={submenu.url}
                      className={`group flex items-center p-2 pl-11 text-base text-white transition duration-75 hover:bg-blue-600 ${
                        isActive(`${submenu.url}`) ? "bg-white/20" : ""
                      }`}
                    >
                      {submenu.name}
                    </Link>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DropdownMenu;
