import React from "react";
import Logo from "../assets/logo/LogoNewChokMain.png";
import FooterRegister from "../components/footer/FooterRegister";
import RegistrationNewCustomerForm from "../components/form/RegisterNewCustomerForm";

const NewCustomerRegister: React.FC = () => {
  return (
    <>
      <div className="max-w-full mx-auto font-kanit">
        <div className="max-w-full h-9 lg:h-14 bg-gradient-to-r from-[#ffffff] via-[#8AC2F5] via-[#54a8f7] to-[#1C00C6]">
          <div className="max-w-screen-lg mx-auto">
            <div className="flex flex-col">
              <div className="flex justify-between h-3 md:h-8 lg:h-14 mt-1 lg:mt-0">
                <div>
                  <img
                    src={Logo}
                    alt="icon-notice"
                    className="w-14 md:w-16 lg:w-28 mx-auto absolute top-0 left-[4%]"
                  />
                </div>
                <div className="absolute top-0 right-0 md:right-7 top-1 md:top-3 text-lg md:text-lg lg:text-2xl text-[#FFF] mt-auto mb-auto  mr-4 md:mr-5 lg:mr-0">
                  บริษัท นิวโชคอำนวย เชียงใหม่ จำกัด
                </div>
              </div>

              <div className="mt-10 mb-10 mx-auto">
                <div className="block md:hidden text-2xl mx-auto text-center">
                  <div>กรอกข้อมูลเพื่อลงทะเบียนสมัคร</div>
                  <div>ใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ</div>
                </div>
                <div className="hidden md:block text-2xl lg:text-3xl">
                  กรอกข้อมูลเพื่อลงทะเบียนสมัครใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ
                </div>
              </div>
              <div className="card lg:rounded-2xl lg:shadow-md my-2 p-4">
                <RegistrationNewCustomerForm />
              </div>
            </div>
          </div>
          <div className="w-full bg-[#1C00C6]">
            <FooterRegister />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCustomerRegister;
