import React, { useRef, useState } from "react";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import CreateNewCustomerForm from "../../components/form/CreateNewCustomerForm";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Create: React.FC = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const submitFormRef = useRef<() => void>(() => {});
  const navigate = useNavigate();

  const getback = () => {
    Swal.fire({
      position: "top",
      title: "ยืนยันการปิดหน้าลงทะเบียน",
      html: "ข้อมูลที่กรอกและยังไม่ได้บันทักจะสูญหาย",
      icon: "warning",
      confirmButtonColor: "#EB4747",
      confirmButtonText: "ยืนยัน",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };
  return (
    <LayoutWeb>
      {loadingPage ? (
        <LoaderPage />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className="w-full">
              <h1 className="text-3xl font-bold text-blue-600 py-2">
                เพิ่มข้อมูลลูกค้าใหม่
              </h1>
            </div>
            <div className="flex items-center justify-end gap-2 w-full">
              <button
                className="w-fit md:w-[16%] bg-white text-[#535252] py-2 px-4 border-[1px] rounded-[6px] border-[#6B7280] "
                onClick={getback}
              >
                ปิดหน้าต่าง
              </button>
              <button
                className="w-fit md:w-[16%] bg-[#13C296] text-white py-2 px-4 rounded-md hover:bg-[#11AE87] "
                onClick={() => submitFormRef.current()}
              >
                บันทึกข้อมูล
              </button>
            </div>
          </div>
          <div className="card rounded-lg shadow-md my-2 p-4 border border-[#99999970] bg-white">
            <CreateNewCustomerForm
              setSubmitForm={(func: any) => (submitFormRef.current = func)}
            />
          </div>
          {/* <div className="card rounded-2xl shadow-md my-2 p-4">
            <CreateNewCustomerMapData/>
          </div>
          <div className="card rounded-2xl shadow-md my-2 p-4">
            <CreateNewCustomerProductPrice/>
          </div>  */}
        </>
      )}
    </LayoutWeb>
  );
};

export default Create;
